
import { Component, Mixins } from "vue-property-decorator";
import { bus } from "@/main";
import { WorkStatus } from "@/store/modules/activity.store";
import { Action } from "vuex-class";
import { FormBase, FormTextarea, FormError, FormSubmit, FormSelect } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormSelect,
        FormTextarea,
        FormError,
        FormSubmit,
    },
})
export default class ActivityLostModal extends Mixins(HasFormErrors) {
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

    show = false;

    activity_id: number = NaN;

    reason = "";

    payload = {
        lost_reason: "",
    };

    reasonOptions = [
        { value: "Eigenaar vindt commissie te hoog", label: "Eigenaar vindt commissie te hoog" },
        { value: "Eigenaar vindt schatting te laag", label: "Eigenaar vindt schatting te laag" },
        { value: "Eigenaar wil zelf verkopen", label: "Eigenaar wil zelf verkopen" },
        { value: "Eigenaar heeft zelf al verkocht", label: "Eigenaar heeft zelf al verkocht" },
        { value: "Eigenaar wil niet meer verkopen", label: "Eigenaar wil niet meer verkopen" },
        { value: "Informatieve schatting", label: "Informatieve schatting" },
        { value: "other", label: "Andere" },
    ];

    mounted() {
        bus.$off("show-activity-lost-reason");
        bus.$on("show-activity-lost-reason", (params: number) => {
            this.show = true;
            this.activity_id = params;
        });
    }

    async submit() {
        try {
            await this.patchActivity({ id: this.activity_id, work_status: WorkStatus.lost, ...this.payload });

            this.$toast.open({ message: "Prospect verloren", type: "success" });
        } catch (e: any) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            this.handleClose();
        }
    }

    handleClose() {
        this.clearErrors();

        this.show = false;
    }

    handleInput() {
        if (this.reason === "other") {
            this.payload.lost_reason = "";
            return;
        }
        this.payload.lost_reason = this.reason;
    }
}
